import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout } from 'components';
import ReviewTeaser from '../components/ReviewTeaser';

class Index extends Component {
  render() {
    const {
      data: { reviews },
    } = this.props;
    return (
      <Layout>
        <div className="jumbotron">
          <h1 className="display-5">Discover character-building literature</h1>
          <p className="lead">There are a lot of bad books out there. We'll help you find the better ones.</p>
        </div>
        <main>
          <div className="container">
            <div className="row">
              {reviews.edges.map((review, i) => {
                const fragments = [
                  <div className="col" key={review.node.uid}>
                    <ReviewTeaser review={review} />
                  </div>,
                ];
                /* add column break for every 2 review */
                if (i % 2) fragments.push(<div className="w-100" />);
                return fragments;
              })}
            </div>
          </div>
        </main>
      </Layout>
    );
  }
}

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    reviews: PropTypes.object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query IndexQuery {
    reviews: allPrismicReview(sort: { fields: [data___date_updated], order: DESC }) {
      edges {
        node {
          uid
          data {
            title
            isbn
            date_updated(formatString: "DD.MM.YYYY")
            teaser {
              html
            }
          }
        }
      }
    }
  }
`;
