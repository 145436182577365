import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from '@emotion/styled/macro';
import Card from './Card';

const StyledImage = styled.img`
  margin: 0 0 0.5em 0.5em;
  width: 8rem;
  max-width: 40%;
`;

class ReviewTeaser extends Component {
  render() {
    const { review } = this.props;
    return (
      <Card header="Book Review">
        <h4 className="card-title">
          <Link to={review.node.uid}>{review.node.data.title}</Link>
        </h4>
        <StyledImage
          src={`https://covers.openlibrary.org/b/isbn/${review.node.data.isbn}-M.jpg`}
          alt={review.node.data.title}
          className="review__image float-right"
        />
        <div dangerouslySetInnerHTML={{ __html: review.node.data.teaser.html }} />
      </Card>
    );
  }
}

export default ReviewTeaser;

ReviewTeaser.propTypes = {
  review: PropTypes.object.isRequired,
};
